import React from 'react'
import './index.css'

import Values from './values'
import Features from './Features'
import AboutUs from './AboutUs'

function About() {
	return (
		<>
			<AboutUs />
			<Values />
			<Features />
		</>
	)
}

export default About

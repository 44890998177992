export const JDS = {
	'python-developer': {
		params: 'python-developer',
		title: 'Python Developer',
		location: 'Pune, India',
		jobType: 'Full Time',
		Salary: 'N/A',

		applyLink: '/careers/python-developer/apply',
		responsibilities: [
			'Write and execute test plans, design and develop test tools, debug and report code bugs and push quality upstream.',
			'Responsible for analyzing use cases and test scenarios.',
			'Implement and maintain test automation and frameworks, performance testing tools, and other quality inspection tools',
			'Test web applications, APIs, client applications',
			'Translate existing manual regression test cases into automated tests.',
			'Debug and troubleshoot complex issues',
			'Analyze test automation results.',
		],
		requirements: [
			'3+ years experience',
			'Open and willing to join an agile, fast paced work environment',
			'Excellent written and oral communication skills with ability to explain, present, and showcase concepts and concerns to technical and non-technical audiences.',
			'Must be familiar with entire Software Development Life Cycle process',
			'Knowledge of scripting language such as Python or Perl.',
			'Experience with automated testing tools and technologies: Selenium, Jenkins, Test Runners',
			'Knowledge of object oriented programming languages such as Java or C#.',
			'Exposure to various web technologies such as HTML, CSS, and JavaScript',
			'Exposure to various versioning systems (Git, TFS, Subversion)',
			'Exposure to test case management tools',
			'Strong analytical and problem solving skills',
			'Self-motivated, able to learn and research issues independently',
			'Knowledge of modern best practices for professional software development including coding standards, code reviews, source control management, build processes, testing, and operations',
			"Bachelor's degree in Computer Science or relevant Engineering degree.",
		],
	},
	'java-developer': {
		title: 'Java Developer',
		location: 'Pune',
		jobType: 'Full Time',
		jd: 'We are looking for an experienced Java Developer who will work closely with the technical lead to identify and establish best practices in the company.',
		Salary: 'N/A',
		responsibilities: [
			'Excellent in OOPS concepts, system design',
			'Strong knowledge of Core Java, Spring, Hibernate and Microservices',
			'Hands-on experience in DB design, SQL, UI Technologies like HTML/CSS, JavaScript,jQuery, etc.',
			'Good knowledge of design patterns',
			'Excellent knowledge of JSP, Servlets, WebServices, JUnit',
			'Experience in Agile software development',
			'Familiarity with JIRA, GIT, Maven',
			'Experience in working directly with a client',
			'Good knowledge in requirement gathering, analysis, and designing',
		],
		requirements: [
			'Design and develop features using Core Java, Spring Boot, and Hibernate',
			'Ability to design database schema, develop views and stored procedures',
			'Participate in user story grooming, design discussions and proposal of solutions',
			'Maintain existing software systems by identifying and correcting software defects',
			' Practice standard development process leveraging agile methodologies such as SCRUM and TDD',
			'Review and analyze business requirements and provide technical feasibility and estimates',
			'Manage development / support functions etc',
		],
		shouldHave: [
			'Excellent written, verbal communication, and and client-facing skills',
			'Exceptional analytical and problem-solving skills',
			'The ability to quickly grasp new concepts and learn new technologies',
			'The traits of a self-motivated, independent, detail-oriented, and responsible team player.',
		],
	},
}
